.rdt_TableCell {
  font-size: 0.8rem !important;
}

.rdt_TableCol {
  font-size: 0.8rem !important;
  font-weight: bold !important;
}

.rdt_source {
  color: blue;
  cursor: pointer;
}
.rdt_note {
  color: red;
  cursor: pointer;
}
