.table-selector {
  & * {
    line-height: 1.5 !important;
  }

  button {
    height: 1.5rem;
    min-width: 4.5rem;
    font-size: 80%;
  }
}
