@font-face {
  font-family: 'OswaldRegular';
  src: url(assets/Oswald-Regular.ttf);
}

@font-face {
  font-family: 'SourceSansRegular';
  src: url(assets/SourceSansPro-Regular.otf);
}

$enable-responsive-font-sizes: true;

$primary: #3d89aa;
$secondary: #777;
$tertiary: #8bb8cc;
$bg-container: rgba(0, 0, 0, 0.03);

$headings-font-family: 'OswaldRegular', sans-serif;
$headings-font-weight: 400;

$font-family-sans-serif: 'SourceSansRegular', sans-serif;
