@import '../../../../variables.scss';
@import 'bootstrap/scss/bootstrap';

.indicators-tree {
  .MuiTreeItem-label {
    font-family: $font-family-sans-serif !important;
    font-size: 0.8rem !important;

    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }
  }

  .MuiTreeItem-iconContainer {
    color: $primary;
  }
}
