.member-selector {
  * {
    font-size: 0.8rem;
  }

  .member-list {
    height: 6rem;
  }

  .shortcuts * {
    font-size: 0.75rem;
  }

  .member-level-selector {
    display: flex;
    height: 24px;
    padding-top: 12px;
    .col {
      width: 16px;
    }
    input:nth-child(1) {
      margin-left: 4px;
    }
    span {
      margin-left: 8px;
      margin-top: -2px;
    }
  }
}