@import '../../../../variables.scss';

.sel-dropdown {
  .btn {
    line-height: 1 !important;
    font-size: 0.7rem !important;
    background-color: $tertiary;
    border-color: $tertiary;
  }

  .dropdown-menu {
    max-height: 11rem;
    max-width: 95vw;
    overflow-y: auto;
    overflow-y: auto;
  }

  .dropdown-item {
    font-size: 0.8rem !important;
  }
}
