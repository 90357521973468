@import '../../../variables.scss';

.table-wrapper {
  max-height: 70rem;
}

.pvtColLabel {
  min-width: 3rem;
}

.pvtRowLabel {
  white-space: pre;
}

.pvtVal {
  text-align: right;
}

.pvtSource {
  margin: 0 0.15rem;
  vertical-align: super;
  font-size: 0.6rem;
  color: blue;
  font-weight: normal;
  cursor: pointer;
}

.pvtNote {
  margin: 0 0.15rem;
  vertical-align: super;
  font-size: 0.6rem;
  color: red;
  font-weight: normal;
  cursor: pointer;
}
