@import '../../variables.scss';

.indic-header {
  .breadcrumb * {
    font-size: 0.8rem;
  }

  nav ol.breadcrumb {
    background-color: white !important;
    margin: 0;
    padding: 0.5rem 0 0.2rem 0;
  }

  h4 {
    font-family: $headings-font-family;
    color: $primary;
  }

  span.badge {
    font-size: 0.7rem;
    font-weight: 300;
    cursor: pointer;
  }
}
