.dim-draggable {
  height: 1.5rem;

  div {
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    flex: 0 0 auto;

    path {
      stroke: white;
    }
  }

  &.disabled {
    background-color: lightgray;
  }
}
