@import '../../variables.scss';
@import 'bootstrap/scss/bootstrap';

.download-cont {
  h4 {
    color: $gray-600;
  }

  .custom-switch label {
    font-size: 0.8rem;

    &::before {
      margin-top: -2px;
    }

    &::after {
      margin-top: -2px;
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
