@import '../../variables.scss';
@import 'bootstrap/scss/bootstrap';

html,
body {
  height: 100%;
  font-family: $font-family-sans-serif;
}

.bg-lightgray {
  background-color: $bg-container;
}

.bg-tertiary {
    background-color: $tertiary;
}

.btn-link:focus {
  box-shadow: none !important;
}

.micro {
  font-size: 75%;
}
