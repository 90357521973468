@import '../../variables.scss';

.indic-selector {
  label {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    color: $primary;
  }

  select * {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.8rem !important;
  }
}
